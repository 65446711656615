import { useDispatch } from 'react-redux';
import { fundamentalGroupAction, updateNotification } from '../../pages/Models/duck/types';
import useLocal from '../../localization';
import React, { useState } from 'react';
import modelsActions from '../../pages/Models/duck/actions';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import useStyles from './style';
import Mixpanel from 'mixPanel';

interface EarningsUpdateActionNotificationListProps {
  notificationForCompany: updateNotification;
}

export const EarningsUpdateActionNotificationList = ({
  notificationForCompany
}: EarningsUpdateActionNotificationListProps) => {
  const preview = useLocal('preview');
  const close = useLocal('close');
  const details = useLocal('details');
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (event: React.ChangeEvent<Record<string, string>>, isExpanded: boolean) => {
    setIsExpanded(isExpanded);
  };

  const onDetails = (event: React.MouseEvent, notificationForCompany: updateNotification) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (!notificationForCompany.seen) {
      dispatch(modelsActions.markCompanyNotificationSeen(notificationForCompany.company.id));
    }
    Mixpanel.track('marketplace:preview_btn_click', {
      ticker: notificationForCompany.company.ticker
    });
    window.open(`/preview/${notificationForCompany.company.id}`);
  };

  const updatedAt = notificationForCompany.updatedAt
    ? moment(notificationForCompany.updatedAt).format('DD, MMM, YYYY HH:mm A')
    : undefined;

  return (
    <Box className={classes.summaryContainer}>
      {/*<Accordion onChange={handleChange} expanded={false}>*/}
      {/*<AccordionSummary*/}
      {/*  classes={{ expandIcon: classes.expandedIcon, expanded: classes.summaryContainer }}*/}
      {/*  expandIcon={*/}
      {/*    <Button variant={'text'} color={'primary'} onClick={onDetails}>*/}
      {/*      {preview}*/}
      {/*    </Button>*/}
      {/*  }*/}
      {/*  aria-controls="panel1a-content"*/}
      {/*  id="panel1a-header"*/}
      {/*>*/}
      <ListItem className={notificationForCompany.seen ? classes.seenNotification : undefined}>
        <ListItemText
          classes={{
            multiline: classes.heading,
            primary: !notificationForCompany.seen ? classes.unSeenNotificationTitle : undefined
          }}
          primary={`${notificationForCompany.company.ticker} - ${notificationForCompany.company.name} has ${notificationForCompany.notificationCount} updates`}
          secondary={updatedAt}
        />
        <Button
          variant={'text'}
          color={'primary'}
          onClick={(event) => onDetails(event, notificationForCompany)}
        >
          {preview}
        </Button>
      </ListItem>

      {/*</AccordionSummary>*/}
      {/*<AccordionDetails>*/}
      {/*  <Box className={classes.expandWrapper}>*/}
      {/*    <Button*/}
      {/*      variant={'text'}*/}
      {/*      onClick={onDetails}*/}
      {/*      color={'primary'}*/}
      {/*      className={classes.detailsButton}*/}
      {/*    >*/}
      {/*      {details}*/}
      {/*    </Button>*/}
      {/*    <List className={classes.logsListContainer}>*/}
      {/*      {notificationForCompany.logs.map((log) => (*/}
      {/*        <>*/}
      {/*          <ListItem key={log.id}>*/}
      {/*            <ListItemText>{`${moment(log.updatedAt).format('DD, MMM, YYYY HH:mm A')} - ${*/}
      {/*              log.action == fundamentalGroupAction.PUBLISH ? 'published' : 'unpublished'*/}
      {/*            } ${log.count} fundamentals for period ${log.period}`}</ListItemText>*/}
      {/*          </ListItem>*/}
      {/*          <Divider />*/}
      {/*        </>*/}
      {/*      ))}*/}
      {/*    </List>*/}
      {/*  </Box>*/}
      {/*</AccordionDetails>*/}
      {/*</Accordion>*/}
    </Box>
  );
};
