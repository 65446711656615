import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import routes from 'components/Router/routes';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useLocal from 'localization';
import useStyles from './style';
import { useDispatch } from 'react-redux';
import { actions, LoginPageSelectors, LoginPageTypes } from './duck';
import CancelIcon from '@material-ui/icons/Cancel';
import { History } from 'core';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const loginObjectShape = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(8, 'Password needs to have at least 8 characters.')
});

const initialValues = {
  email: '',
  password: ''
};

const Login: React.FC = () => {
  const error = useSelector(LoginPageSelectors.error);
  const hasError = useSelector(LoginPageSelectors.hasError);
  const isLoading = useSelector(LoginPageSelectors.isLoading);

  const loginHeading = useLocal('login_heading');

  const loginEmail = useLocal('login_email');

  const loginPassword = useLocal('login_password');

  const loginText = useLocal('login');

  const troubleSigning = useLocal('trouble_signing');

  const resetPassword = useLocal('reset_password');

  const didntGetAnEmail = useLocal('didnt_get_an_email');

  const passwordExpiredText = useLocal('password_expired');
  const shouldResetPasswordText = useLocal('should_reset_password');
  const resend = useLocal('resend');
  const signupForFree = useLocal('signup_for_free');
  const showReSendEmail = useSelector(LoginPageSelectors.showReSendEmail);
  const shouldResetPassword = useSelector(LoginPageSelectors.shouldResetPassword);

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const { state }: { state: { from?: { pathname: string; search: string } } } = useLocation();
  const classes = useStyles();
  const redirectTo = state?.from?.pathname ? state.from.pathname + state.from.search : null;

  const formikObj = useFormik<LoginPageTypes.loginFormField>({
    initialValues,
    validateOnBlur: true,
    validationSchema: loginObjectShape,
    onSubmit: ({ email, password }) => {
      setEmail(email);
      dispatch(actions.loginRequest(email, password, redirectTo));
    }
  });

  const handleResend = () => {
    dispatch(actions.resendEmailRequest(email));
  };

  useEffect(() => {
    return function clearUp() {
      dispatch(actions.clearLoginState());
    };
  }, [dispatch]);

  return (
    <form onSubmit={formikObj.handleSubmit}>
      <Dialog maxWidth="xs" open={true} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.title} id="form-dialog-title">
          <h2>{loginHeading}</h2>
          <IconButton
            data-testid="api-settings-close-btn"
            onClick={() => History.push(routes.HOME)}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        {hasError && (
          <Alert severity="error" data-testid="server-error">
            {error}
            {showReSendEmail && (
              <p>
                {didntGetAnEmail}
                <button className={classes.resendButton} onClick={handleResend}>
                  {resend}
                  {isLoading && <CircularProgress size={18} />}
                </button>
              </p>
            )}
          </Alert>
        )}
        <DialogContent>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="email" error={hasError}>
              {loginEmail}
            </InputLabel>
            <OutlinedInput
              id="email"
              inputProps={{ 'data-testid': 'login-email' }}
              name="email"
              type="text"
              onChange={formikObj.handleChange}
              value={formikObj.values.email}
              labelWidth={103}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.email && formikObj.touched.email) || hasError}
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.email && formikObj.touched.email)}
            >
              {formikObj.touched.email && formikObj.errors.email}
            </FormHelperText>
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="password" error={hasError}>
              {loginPassword}
            </InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              inputProps={{ 'data-testid': 'login-password' }}
              type={showPassword ? 'text' : 'password'}
              value={formikObj.values.password}
              onChange={formikObj.handleChange}
              error={Boolean(formikObj.errors.password && formikObj.touched.password) || hasError}
              onBlur={formikObj.handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            <FormHelperText
              data-testid="password-error"
              error={Boolean(formikObj.errors.password && formikObj.touched.password)}
            >
              {formikObj.touched.password && formikObj.errors.password}
            </FormHelperText>
          </FormControl>
          <DialogActions className={classes.dialogActions}>
            <Button
              type="submit"
              onClick={formikObj.submitForm}
              color="primary"
              data-testid="login-submit"
              disabled={isLoading}
              variant="contained"
            >
              {loginText}
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
            <Button
              type="submit"
              onClick={() => History.push(routes.REGISTRATION)}
              color="primary"
              data-testid="login-submit"
              variant="outlined"
            >
              {signupForFree}
            </Button>
          </DialogActions>
          <Typography color="textSecondary">
            {troubleSigning} <Link to={routes.FORGET_PASSWORD}>{resetPassword}</Link>
          </Typography>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        isOpen={shouldResetPassword}
        onConfirm={() => {
          dispatch(actions.setShouldResetPassword(false));
          History.replace(routes.FORGET_PASSWORD);
        }}
        title={passwordExpiredText}
        content={shouldResetPasswordText}
        onClose={() => {
          dispatch(actions.setShouldResetPassword(false));
        }}
      />
    </form>
  );
};

export default Login;
