import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import withWidth from '@material-ui/core/withWidth';

import useLocal from 'localization';

import SubHeader from 'components/SubHeader';
import ModelSidebar from 'components/ModelSidebar';

import { actions, modelsSelectors } from './duck';
import useStyles from './style';

const IndustryModels: React.FC = () => {
  const classes = useStyles();

  const modelTitle = useLocal('industry_model');

  const dispatch = useDispatch();

  const industriesList = useSelector(modelsSelectors.getIndustiesList);
  const totalNumberOfIndustries = useSelector(modelsSelectors.getTotalNumberOfIndustries);
  const hasIndustryError = useSelector(modelsSelectors.hasIndustryError);
  const isIndustryLoading = useSelector(modelsSelectors.isIndustryLoading);
  const industryError = useSelector(modelsSelectors.industryError);
  const selectedIndustry = useSelector(modelsSelectors.getSelectedIndustry);

  useEffect(() => {
    dispatch(actions.getIndustries());
  }, [dispatch]);

  const handelSectorChange = (sectorId: string) => {
    sectorId; // need to add handelSectorChange handler when models are added in grid
  };
  return (
    <Box pt={{ xs: 19, sm: 17 }}>
      <SubHeader
        title={`${modelTitle} ${totalNumberOfIndustries ? `(${totalNumberOfIndustries})` : ''}`}
      ></SubHeader>

      <Container className={classes.container} maxWidth="xl" data-testid="page-container">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} md={2} lg={2} key="sidebar-box">
            <ModelSidebar
              hasSectorError={hasIndustryError}
              sectorError={industryError}
              isSectorLoading={isIndustryLoading}
              sectorList={industriesList}
              selectFilter={handelSectorChange}
              selectedSector={selectedIndustry}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={10} lg={10} key="content-box"></Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withWidth()(IndustryModels);
