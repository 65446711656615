import { createSelector } from 'reselect';

import { ModelsState, sectorList, modelObject, clientModels, company } from './types';
import { RootState } from 'store';
const getModelState = (state: RootState): ModelsState => state.ModelsReducer;
import { sumBy } from 'lodash';

/**
 * Sectors
 */
const hasSectorError = createSelector(
  [getModelState],
  (ModelsState: ModelsState): boolean => ModelsState.isSectorError
);
const sectorError = createSelector(
  [getModelState],
  (ModelsState: ModelsState): string | null => ModelsState.sectorError
);

const isSectorLoading = createSelector(
  [getModelState],
  (ModelsState: ModelsState): boolean => ModelsState.isSectorLoading
);

const getSectorsList = createSelector(
  [getModelState],
  (ModelsState: ModelsState): sectorList => ModelsState.sectors
);
const getTotalNumberOfModels = createSelector(
  [getModelState],
  (ModelsState: ModelsState): number | null => ModelsState.totalNumberOfModels
);
const getSelectedSector = createSelector(
  [getModelState],
  (ModelsState: ModelsState): string => ModelsState.seletedSectorId
);

/**
 * Models
 */

const hasModelsError = createSelector(
  [getModelState],
  (ModelsState: ModelsState): boolean => ModelsState.isModelsError
);
const modelsError = createSelector(
  [getModelState],
  (ModelsState: ModelsState): string | null => ModelsState.sectorError
);

const isUpdating = createSelector(
  [getModelState],
  (ModelsState: ModelsState): boolean => ModelsState.isUpdating
);

const isModelsLoading = createSelector(
  [getModelState],
  (ModelsState: ModelsState): boolean => ModelsState.isModelsLoading
);
const getModelsList = createSelector(
  [getModelState],
  (ModelsState: ModelsState): modelObject[] | null => ModelsState.modelList
);

const isIndustryModelLoading = createSelector(
  [getModelState],
  (ModelsState: ModelsState): number | null => ModelsState.isIndustryModelLoading
);
const getTotalNumberOfIndustries = createSelector(
  [getModelState],
  (ModelsState: ModelsState): number => ModelsState.totalNumberOfIndustries
);
const getSubscriptionPendingModel = createSelector(
  [getModelState],
  (ModelsState: ModelsState): string | null => ModelsState.subscriptionPending
);

const getMyModels = createSelector(
  [getModelState],
  (ModelsState: ModelsState): clientModels[] | null => ModelsState.myModel
);

const latestModelStatus = createSelector(
  [getModelState],
  (ModelsState: ModelsState): string | null => ModelsState.stateName
);

const downloadingModelType = createSelector(
  [getModelState],
  (ModelsState: ModelsState): string | null => ModelsState.downloadingModelType
);

const selectedCompany = createSelector(
  [getModelState],
  (ModelsState: ModelsState): company | null => ModelsState.selectedCompany
);

// Unsubscribe models selectors

const isUnsubscribeLoading = createSelector(
  [getModelState],
  (ModelsState: ModelsState): boolean => ModelsState.isUnsubscribeLoading
);

const isUnsubscribeError = createSelector(
  [getModelState],
  (ModelsState: ModelsState): boolean => ModelsState.isUnsubscribeError
);

const unsubscrbieError = createSelector(
  [getModelState],
  (ModelsState: ModelsState): string | null => ModelsState.unsubscrbieError
);

const unsubscribed = createSelector(
  [getModelState],
  (ModelsState: ModelsState): boolean => ModelsState.unsubscribed
);

// update notifications
const earningsUpdateNotifications = createSelector(
  [getModelState],
  (ModelState) => ModelState.companyUpdateNotifications
);

const isUpdateNotificationsLoading = createSelector(
  [getModelState],
  (ModelState) => ModelState.isUpdateNotificationsLoading
);

const earningsUpdateNotificationsUnSeenCount = createSelector(
  [getModelState],
  (ModelState) =>
    Object.values(ModelState.companyUpdateNotifications).filter(
      (companyNotification) => !companyNotification.seen
    ).length
);

const earningsUpdateNotificationsSeenCount = createSelector(
  [getModelState, earningsUpdateNotificationsUnSeenCount],
  (ModelState, unSeenCount) =>
    Object.values(ModelState.companyUpdateNotifications).length - unSeenCount
);

// updated fundamental groups
const updatedFundamentalGroupsLoading = createSelector(
  [getModelState],
  (ModelState) => ModelState.isUpdatedFundamentalsGroupsLoading
);

const updatedFundamentalGroups = createSelector(
  [getModelState],
  (ModelState) => ModelState.updatedFundamentalGroups
);

const notificationCompany = createSelector(
  [getModelState],
  (ModelState) => ModelState.notificationCompany
);

//updated fundamentals
export const updatedFundamentalsByGroupIdLoading = createSelector(
  [getModelState],
  (ModelState) => ModelState.updatedFundamentalsByGroupIdLoading
);

export const updatedFundamentalsByGroupId = createSelector(
  [getModelState],
  (ModelState) => ModelState.updatedFundamentalsByGroupId
);

// notifications config
const isEarningNotificationsEnabled = createSelector([getModelState], (ModelState) => {
  if (!ModelState.earningUpdateNotificationsConfig || !process.env.REACT_APP_ENV) {
    return false;
  }
  return Boolean(ModelState.earningUpdateNotificationsConfig[process.env.REACT_APP_ENV]);
});

const isMarkingAllNotificationsLoading = createSelector(
  [getModelState],
  (ModelState) => ModelState.isMarkingAllNotificationsLoading
);

export default {
  hasSectorError,
  sectorError,
  isSectorLoading,
  getSectorsList,
  getTotalNumberOfModels,
  getSelectedSector,
  hasModelsError,
  modelsError,
  isModelsLoading,
  getModelsList,
  isUpdating,
  isIndustryModelLoading,
  getTotalNumberOfIndustries,
  getMyModels,
  getSubscriptionPendingModel,
  latestModelStatus,
  isUnsubscribeLoading,
  isUnsubscribeError,
  unsubscrbieError,
  selectedCompany,
  unsubscribed,
  earningsUpdateNotifications,
  updatedFundamentalGroupsLoading,
  updatedFundamentalGroups,
  notificationCompany,
  isEarningNotificationsEnabled,
  downloadingModelType,
  isMarkingAllNotificationsLoading,
  isUpdateNotificationsLoading,
  earningsUpdateNotificationsSeenCount,
  earningsUpdateNotificationsUnSeenCount
};
