import React from 'react';

import useLocal from 'localization';

import Props from './types';
import useStyles from './style';
import { Box, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import ToggleOffTwoToneIcon from '@material-ui/icons/ToggleOffTwoTone';
import ToggleOnTwoToneIcon from '@material-ui/icons/ToggleOnTwoTone';
import Badge from '@material-ui/core/Badge';

const TvSidebar: React.FC<Props> = ({
  fundamentlInfo,
  printAsPdf,
  locateDatapoint,
  isDocument,
  snippetMode,
  onToggleMode
}: Props) => {
  const classes = useStyles();
  const {
    company: { name, ticker },
    document,
    type
  } = fundamentlInfo;
  const srcFilling = useLocal('src_filling');
  const srcExhibit = useLocal('src_exhibit');
  const srcFiledOn = useLocal('src_filed_on');
  const srcSaveAsPdf = useLocal('src_save_as_pdf');
  const srcLocateDataPoint = useLocal('src_locate_data_point');
  const srcViewOnSecEdger = useLocal('src_view_on_sec_edger');
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box height={{ sm: 'auto', md: '80vh' }} bgcolor="white.main" displayPrint="none">
      <Box p={1} pb={3} fontWeight="fontWeightBold" data-testid="company-name">
        <h2>
          {ticker} - {name}
        </h2>
        {type !== 'COMPOSITE' && document && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {srcFilling}
            </Grid>
            <Grid item xs={6}>
              {document?.filingType}
            </Grid>

            <Grid item xs={6}>
              {srcExhibit}
            </Grid>
            <Grid item xs={6}>
              {document?.documentType}
            </Grid>

            <Grid item xs={6}>
              {srcFiledOn}
            </Grid>
            <Grid item xs={6}>
              {document?.reportedDate}
            </Grid>
          </Grid>
        )}
      </Box>

      <Box
        marginTop={{ md: 15, sm: 0 }}
        fontWeight="fontWeightBold"
        display="flex"
        flexDirection={{ xs: 'row', sm: 'column' }}
        justifyContent="space-between"
        p={1}
      >
        <Button onClick={printAsPdf} className={classes.yellowBtn} size="large" variant="contained">
          <SaveIcon className={classes.icons} />
          {!isSmallScreen && srcSaveAsPdf}
        </Button>

        {type !== 'COMPOSITE' && (
          <>
            {!isDocument && (
              <Button
                className={classes.blueBtn}
                size="large"
                variant="contained"
                onClick={locateDatapoint}
              >
                <MyLocationIcon className={classes.icons} />
                {!isSmallScreen && srcLocateDataPoint}
              </Button>
            )}

            {document && (
              <Button
                target="_blank"
                href={document.url}
                className={classes.blueBtn}
                size="large"
                variant="contained"
              >
                <VisibilityIcon className={classes.icons} />
                {!isSmallScreen && srcViewOnSecEdger}
              </Button>
            )}
            <Button
              className={classes.blueBtn}
              size="large"
              variant="contained"
              onClick={onToggleMode}
            >
              {snippetMode ? (
                <ToggleOffTwoToneIcon className={classes.icons} />
              ) : (
                <ToggleOnTwoToneIcon className={classes.icons} />
              )}{' '}
              <Badge badgeContent={'New'} color="error" classes={{ root: classes.badgeRoot }}>
                {' '}
                Toggle {snippetMode ? 'Document mode' : 'Snippet mode'}
              </Badge>
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TvSidebar;
