import queryString from 'query-string';

export const setQueryParams = (params: Record<string, any>) => {
  if (window) {
    window.history.pushState({}, '', `?${queryString.stringify(params)}`);
  }
};

export const getQueryParams = (): any => {
  if (window) {
    return queryString.parse(window.location.search);
  }
  return {};
};

export const updateQueryParams = (obj: any) => {
  const params = getQueryParams();
  const updatedParams = { ...params, ...obj };
  for (const key in updatedParams) {
    if (updatedParams[key] === undefined || obj[key] === null) {
      delete updatedParams[key];
    }
  }
  setQueryParams(updatedParams);
};
