import { put, takeLatest } from 'redux-saga/effects';
import { GraphQL, gql, History } from 'core';
import Cookies from 'js-cookie';
import routes from 'components/Router/routes';
import Mixpanel from 'mixPanel';
import actionTypes, { signupTrialAction } from './types';
import actions from './actions';
import { getLocalValue } from 'localization';
import { actions as userDetailAction } from 'userDetail';
import { selectState } from 'store';
import { ACCESS_TOKEN_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME } from 'pages/Login/duck/types';

export function* signupTrialSaga({ email, password, hashcode }: ReturnType<signupTrialAction>) {
  yield put(actions.signupTrialPending());

  try {
    const {
      data: {
        createUserWithTrial: { accessToken, refreshToken, success, errors }
      }
    } = yield GraphQL.mutate({
      mutation: gql`
        mutation createUserWithTrialMutation(
          $email: String!
          $password: String!
          $hashcode: String!
        ) {
          createUserWithTrial(email: $email, password: $password, hashcode: $hashcode) {
            success
            errors
            accessToken
            refreshToken
          }
        }
      `,
      variables: { email, password, hashcode }
    });

    if (success) {
      const signupSccussful: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'signup_sccussful')
      );
      yield put(actions.signupTrialSuccess(signupSccussful));
      yield put(userDetailAction.getUserDetails());
      alert(signupSccussful);
      setTimeout(() => {
        if (accessToken && refreshToken) {
          Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken);
          Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken);
          Mixpanel.identify(email);
          Mixpanel.people.set({
            $email: email,
            $last_login: new Date()
          });
          History.push(routes.MODELS);
        }
      }, 500);
    } else if (errors && errors.length > 0) {
      const unknown: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'unknown')
      );
      switch (errors[0]) {
        case 'DUPLICATE_EMAIL':
          const signupDuplicateEmail: string = yield selectState<string | undefined>((state) =>
            getLocalValue(state, 'signup_duplicate_email')
          );
          yield put(actions.signupTrialError(signupDuplicateEmail));
          alert(signupDuplicateEmail);
          setTimeout(() => {
            History.push(routes.LOGIN);
          }, 500);
          break;
        case 'UNKNOWN':
          yield put(actions.signupTrialError(unknown));
          break;
        case 'WRONG_LINK':
          const signupWrongLink: string = yield selectState<string | undefined>((state) =>
            getLocalValue(state, 'signup_wrong_link')
          );
          yield put(actions.signupTrialError(signupWrongLink));
          break;
        default:
          yield put(actions.signupTrialError(unknown));
          break;
      }
    }
  } catch (error) {
    yield put(actions.signupTrialError((error as { message: string })?.message));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.SIGNUP_TRIAL, signupTrialSaga);
}
