import actionTypes, {
  getIndustryAction,
  setIndustryAction,
  getIndustryPendingAction,
  getIndustryErrorAction
} from './types';

/**
 * Industries
 */
const getIndustries: getIndustryAction = () => ({
  type: actionTypes.GET_INDUSTRIES
});

const getIndustriesPending: getIndustryPendingAction = () => ({
  type: actionTypes.GET_INDUSTRIES_PENDING
});

const getIndustriesError: getIndustryErrorAction = (error) => ({
  type: actionTypes.GET_INDUSTRIES_ERROR,
  error
});

const setIndustries: setIndustryAction = (sectorsCompsheet) => ({
  type: actionTypes.SET_INDUSTIRES,
  sectorsCompsheet
});

export default {
  getIndustries,
  getIndustriesPending,
  getIndustriesError,
  setIndustries
};
