import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import useLocal from 'localization';
import Mixpanel from 'mixPanel';

import ModelItem from 'components/ModelItem';
import ModelsTable from 'components/ModelsTable';

import Props from './types';

const ModelList: React.FC<Props> = ({
  industry,
  clientModels,
  showList,
  onSubscribeModel,
  onUnSubscribeModel,
  numberOfModelsInIndustry,
  numberOfIndustriesByDefault,
  loadMore,
  isIndustryModelLoading,
  subscriptionPendingModel,
  isFreeTier,
  allowedIndustries,
  maxSubscriptions,
  subscribedCount,
  isTrialTier
}: Props) => {
  const loadMoreLabel = useLocal('load_more');
  const noCredit = maxSubscriptions <= subscribedCount;
  const clientModelsNotDisabled = clientModels?.filter((model) => !model?.company?.disabled);
  return (
    <>
      {clientModelsNotDisabled?.length ? (
        <Box border={1} mb={2} borderColor="border.main" data-testid="industry-box">
          {clientModelsNotDisabled?.length && (
            <Box
              fontWeight="fontWeightBold"
              fontSize={16}
              p={1}
              m={0}
              pl={3}
              bgcolor="headingBG.main"
              data-testid="industry-title"
            >
              {industry?.name} (
              <span data-testid="model-count-in-industry">{numberOfModelsInIndustry}</span>)
            </Box>
          )}
          <Box m={0} p={3}>
            <Grid container spacing={1}>
              {showList && clientModelsNotDisabled ? (
                <ModelsTable
                  onSubscribeModel={onSubscribeModel}
                  onUnSubscribeModel={onUnSubscribeModel}
                  clientModels={clientModelsNotDisabled}
                  subscriptionPendingModel={subscriptionPendingModel}
                  showNotAllowed={isFreeTier && !allowedIndustries?.includes(industry?.id)}
                  noCredit={isFreeTier && noCredit && allowedIndustries?.includes(industry?.id)}
                  isTrialTier={isTrialTier}
                />
              ) : (
                clientModelsNotDisabled?.length &&
                clientModelsNotDisabled.map((item, index) => {
                  return (
                    <ModelItem
                      onSubscribeModel={onSubscribeModel}
                      onUnSubscribeModel={onUnSubscribeModel}
                      showSubscribe={!item.isSubscribed}
                      showNotAllowed={isFreeTier && !allowedIndustries?.includes(industry?.id)}
                      noCredit={isFreeTier && noCredit && allowedIndustries?.includes(industry?.id)}
                      showDownload={item.isSubscribed}
                      isTrialTier={isTrialTier}
                      clientModelIndex={index}
                      key={`${item.id}-${item.isSubscribed}-${
                        subscriptionPendingModel === item.id
                      }`}
                      subscriptionPendingModel={subscriptionPendingModel}
                      {...item}
                    />
                  );
                })
              )}
            </Grid>
            {numberOfModelsInIndustry > numberOfIndustriesByDefault &&
              clientModels?.length != numberOfModelsInIndustry &&
              loadMore && (
                <Box textAlign="center" pt={2}>
                  <Button
                    disabled={Boolean(
                      isIndustryModelLoading && isIndustryModelLoading === parseInt(industry?.id)
                    )}
                    data-testid="load-more-btn"
                    color="primary"
                    onClick={() => {
                      loadMore(industry?.id);
                      Mixpanel.track('Marketplace?:load_more', industry);
                    }}
                  >
                    {loadMoreLabel}
                    {isIndustryModelLoading &&
                      isIndustryModelLoading === parseInt(industry?.id) && (
                        <CircularProgress size={24} />
                      )}
                  </Button>
                </Box>
              )}
          </Box>
        </Box>
      ) : (
        ' '
      )}
    </>
  );
};

export default React.memo(ModelList);
