import actionTypes, { IndustyState, actionCreatorTypes } from './types';
import { allModelId } from 'components/ModelSidebar/types';

const initialState: IndustyState = {
  isIndustryLoading: false,
  isIndustryError: false,
  industryError: null,
  sectorsCompsheet: null,
  seletedSectorId: allModelId,
  totalNumberOfIndustries: null
};

const reducer = (state: IndustyState = initialState, action: actionCreatorTypes): IndustyState => {
  switch (action.type) {
    case actionTypes.GET_INDUSTRIES_PENDING:
      return {
        ...state,
        isIndustryLoading: true
      };
    case actionTypes.GET_INDUSTRIES_ERROR:
      return {
        ...state,
        isIndustryLoading: false,
        industryError: action.error ?? null,
        isIndustryError: true
      };
    case actionTypes.SET_INDUSTIRES:
      return {
        ...state,
        isIndustryLoading: false,
        isIndustryError: false,
        industryError: null,
        sectorsCompsheet: action.sectorsCompsheet?.sectorList ?? null,
        totalNumberOfIndustries: action?.sectorsCompsheet?.totalNumberOfModels ?? null
      };
    default:
      return state;
  }
};

export default reducer;
