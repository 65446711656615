import { createSelector } from 'reselect';

import { IndustyState, sectorList } from './types';
import { RootState } from 'store';
const getIndustryState = (state: RootState): IndustyState => state.IndustryModelReducer;

/**
 * Sectors
 */
const hasIndustryError = createSelector(
  [getIndustryState],
  (IndustyState: IndustyState): boolean => IndustyState.isIndustryError
);
const industryError = createSelector(
  [getIndustryState],
  (IndustyState: IndustyState): string | null => IndustyState.industryError
);

const isIndustryLoading = createSelector(
  [getIndustryState],
  (IndustyState: IndustyState): boolean => IndustyState.isIndustryLoading
);

const getIndustiesList = createSelector(
  [getIndustryState],
  (IndustyState: IndustyState): sectorList => IndustyState.sectorsCompsheet
);
const getTotalNumberOfIndustries = createSelector(
  [getIndustryState],
  (IndustyState: IndustyState): number | null => IndustyState.totalNumberOfIndustries
);
const getSelectedIndustry = createSelector(
  [getIndustryState],
  (IndustyState: IndustyState): string => IndustyState.seletedSectorId
);

export default {
  hasIndustryError,
  industryError,
  isIndustryLoading,
  getIndustiesList,
  getTotalNumberOfIndustries,
  getSelectedIndustry
};
