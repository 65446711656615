import {
  Fundamental,
  FundamentalByPeriod,
  FundamentalFilters,
  FundamentalStatus,
  SectionWithSeries,
  Series,
  SeriesWithFundamentalsByPeriod
} from './types';
import React, { useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getModelLivePreviewSections, getSeries } from './service';
import { Box, Checkbox, Chip, FormControlLabel, Tooltip } from '@material-ui/core';
import useLocal from '../../localization';
import moment from 'moment';
import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import SearchField from '../../components/SearchField';
import { isEmpty } from 'lodash';
import AppDataGrid from '../../components/AppDataGrid';
import { Link } from '@mui/material';
import { FilingTypeFilters } from './FilingTypeFilters';
import Mixpanel from 'mixPanel';
import { cloneDeep } from '@apollo/client/utilities';
import { PUBLISHED_COLOR, COMPOSITE, FILLER_VALUE, TYPE_MAP, COLORS_MAP } from './constants';
import { CheckCircleOutline, QueryBuilder } from '@material-ui/icons';

interface LiveModelPreviewTableProps {
  companyId: string;
}

const PAGE_SIZE = 100;

const formatDateTime = (timeStr: string) => moment(timeStr).format('YYYY-MM-DD, h:mm A zz');

const getTimeFormatted = (seconds: number) => {
  let unit = 'minutes';
  let value = (seconds ?? 0) / 60; // min
  if (value >= 60) {
    value /= 60; //hours
    unit = 'hours';
  }
  if (unit === 'hours' && value >= 24) {
    value /= 24; //days
    unit = 'days';
  }
  return { unit, value: value.toFixed(1) };
};

export const LiveModelPreviewTable = ({ companyId }: LiveModelPreviewTableProps) => {
  const classes = useStyles();
  const [filters, setFilters] = useState<FundamentalFilters>({
    query: undefined,
    filingTypes: undefined,
    publishedOnly: false
  });
  const [tempQuery, setTempQuery] = useState<string | undefined>();
  const [sectionId, setSectionId] = useState<string>();
  const [pageNumberBySectionId, setPageNumberBySectionId] = useState<{ [key: string]: number }>({});

  const [allSectionsWithSeriesById, setAllSectionsWithSeriesById] = useState<{
    [id: string]: SectionWithSeries;
  }>({});

  const queryKey = companyId
    ? [
        'modelLivePreview',
        {
          companyId,
          ...filters
        }
      ]
    : [];

  const { isLoading: isSectionsLoading, data: sectionsById } = useQuery(
    queryKey,
    () =>
      companyId
        ? getModelLivePreviewSections(
            companyId,
            filters.query,
            isEmpty(filters.filingTypes) ? undefined : filters.filingTypes,
            filters.publishedOnly
          )
        : undefined,
    {
      enabled: !!companyId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false
    }
  );

  const {
    isLoading: isSectionDetailsLoading,
    data: sectionDetails,
    isFetchingNextPage: isFetchingNextSectionDetails,
    fetchNextPage: fetchNextSectionDetailsPage
  } = useInfiniteQuery(
    sectionId && companyId ? ['sectionDetails', { companyId, sectionId }] : [],
    ({ pageParam = 0 }) =>
      companyId && sectionId && !!sectionsById && !!sectionsById[sectionId]
        ? getSeries(
            companyId,
            sectionsById[sectionId]?.seriesIds.slice(
              parseInt(pageParam) * PAGE_SIZE,
              (parseInt(pageParam) + 1) * PAGE_SIZE
            ),
            parseInt(pageParam)
          )
        : undefined,
    {
      enabled: !!companyId && !!sectionId && !!sectionsById && !!sectionsById[sectionId],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      getNextPageParam: (lastPage) => (lastPage?.page ?? 0) + 1,
      getPreviousPageParam: (firstPage, allPages) =>
        allPages ? allPages[allPages.length - 1]?.page : 0
    }
  );

  const updatedAt = useLocal('updated_at');
  const searchText = useLocal('search_text');

  const changeFilters = (filter: Partial<FundamentalFilters>) => {
    setFilters((prevFilter) => ({ ...prevFilter, ...filter }));
    setSectionId(undefined);
    Mixpanel.track('marketplace:company_preview_filters_change', { filters: filter });
  };

  const mainColumns: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Section Name',
        minWidth: window.innerWidth * 0.9,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const minimumPublishedInTime = (params.row as SectionWithSeries).minimumPublishedIn;
          const minimumPublishedInUnitValue = minimumPublishedInTime
            ? getTimeFormatted(minimumPublishedInTime)
            : null;
          let dataPointTime = null;
          if (
            (minimumPublishedInUnitValue?.unit === 'hours' &&
              parseFloat(minimumPublishedInUnitValue.value) > 1) ||
            minimumPublishedInUnitValue?.unit === 'days'
          ) {
            dataPointTime = '> 1 hour';
          } else if (!!minimumPublishedInUnitValue) {
            dataPointTime = `${minimumPublishedInUnitValue.value} ${minimumPublishedInUnitValue.unit}`;
          }

          return (
            <div className={classes.section}>
              <div
                style={{
                  backgroundColor: (params.row as SectionWithSeries).isPublished
                    ? PUBLISHED_COLOR
                    : undefined
                }}
              >
                {(params.row as SectionWithSeries).name}
              </div>
              <div className={classes.sectionContent}>
                {!!dataPointTime && (
                  <Chip
                    icon={<QueryBuilder className={classes.dataPointIcon} />}
                    variant={'outlined'}
                    label={dataPointTime}
                    className={classes.dataPoint}
                  />
                )}
                {(params.row as SectionWithSeries).publishedFundamentalsCount > 0 && (
                  <Chip
                    icon={<CheckCircleOutline className={classes.fundamentalCountIcon} />}
                    variant={'outlined'}
                    label={`${
                      (params.row as SectionWithSeries).publishedFundamentalsCount
                    } data points`}
                    className={classes.fundamentalCount}
                  />
                )}
              </div>
            </div>
          );
        }
      }
    ],
    []
  );
  const [columns, setColumns] = useState(mainColumns);

  useEffect(() => {
    if (sectionsById && !isSectionsLoading) {
      setAllSectionsWithSeriesById(sectionsById);
    }

    if (sectionId && sectionsById && sectionDetails && !isSectionDetailsLoading) {
      const newSectionsWithSeriesById = cloneDeep(allSectionsWithSeriesById);
      const section = sectionsById[sectionId];
      const series: SeriesWithFundamentalsByPeriod[] = [];
      sectionDetails.pages.forEach((page) => {
        page?.data.series.forEach((currentSeries) => {
          const fundamentalsByPeriod: FundamentalByPeriod = currentSeries.fundamentals.reduce(
            (collector, fundamental) => {
              collector[fundamental.period] = fundamental;
              return collector;
            },
            {} as FundamentalByPeriod
          );
          series.push({ ...currentSeries, fundamentals: fundamentalsByPeriod });
        });
      });
      newSectionsWithSeriesById[sectionId] = { ...section, series };
      setAllSectionsWithSeriesById(newSectionsWithSeriesById);
    }
    if (sectionDetails && !isSectionDetailsLoading) {
      let periodColumns: GridColumns = [];
      const periodSet = new Set<string>();
      sectionDetails.pages.forEach((page) => {
        page?.data.periods?.forEach((period) => periodSet.add(period));
      });
      const periods = Array.from(periodSet);
      periods.forEach((period) => {
        const column = {
          field: `fundamentals-${period}`,
          headerName: period,
          width: 130,
          filterable: false,
          sortable: false,
          renderCell: (params: GridCellParams) => {
            const fundamental = params.row.fundamentals[period] as Fundamental;
            if (!fundamental) {
              return <span>-</span>;
            }
            const document = fundamental.document;
            const status = fundamental?.status;
            const type = fundamental?.type;

            let value;
            let title = '';
            if ([FundamentalStatus.PUBLISHED, FundamentalStatus.CLIENTVIEW].includes(status)) {
              value = parseFloat(fundamental?.normalizedValue ?? fundamental?.value ?? 0).toFixed(
                2
              );
              const documentDropTime =
                document && ![FILLER_VALUE, COMPOSITE].includes(type)
                  ? formatDateTime(document.createdAt)
                  : `unknown (due to ${TYPE_MAP[type]})`;
              const publishedAt = fundamental?.publishedAt;
              const publishTime = fundamental ? formatDateTime(publishedAt) : 'unknown';
              const formattedPublishedIn = fundamental.publishedIn
                ? getTimeFormatted(fundamental.publishedIn)
                : null;
              const publishedIn =
                formattedPublishedIn && ![FILLER_VALUE, COMPOSITE].includes(type)
                  ? `${formattedPublishedIn.value} ${formattedPublishedIn.unit}`
                  : `unknown (due to ${TYPE_MAP[type]})`;

              const createdAtTime = formatDateTime(fundamental.createdAt);
              if (status === FundamentalStatus.PUBLISHED) {
                title = `This data is updated via Incremental Updates\nFiling dropped at ${documentDropTime}\nUpdating started at ${createdAtTime}\nData updated at ${publishTime}\nTotal time taken ${publishedIn}`;
              } else {
                title = `This data is updated via regular model upload\nFiling dropped at ${documentDropTime}\nUpdating started at ${createdAtTime}\nModel updated at ${publishTime}\nTotal time taken ${publishedIn}`;
              }
            } else {
              value = status?.replace('_', ' ');
            }

            return (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={title} placement={'top'}>
                <span
                  style={{
                    color: COLORS_MAP[status],
                    backgroundColor:
                      status === FundamentalStatus.PUBLISHED && !!updatedAt
                        ? PUBLISHED_COLOR
                        : undefined
                  }}
                >
                  {value ?? '-'}
                </span>
              </Tooltip>
            );
          }
        };
        periodColumns.push(column);
      });
      periodColumns = [
        {
          field: 'name',
          headerName: 'Calender',
          minWidth: 300,
          filterable: false,
          sortable: false
        },
        {
          field: 'filingType',
          headerName: 'Filing Type',
          type: 'string',
          renderCell: (params) => {
            const document = (params.row as Series).document;
            return !document?.filingType ? (
              '-'
            ) : (
              <Link
                color={'primary'}
                className={classes.documentUrl}
                href={document.url}
                target={'_blank'}
              >
                {document.filingType}
              </Link>
            );
          },
          width: 200,
          filterable: false,
          sortable: false
        },
        ...(periodColumns ?? [])
      ];
      setColumns(periodColumns);
    }
  }, [
    sectionsById,
    isSectionsLoading,
    companyId,
    sectionId,
    sectionDetails,
    isSectionDetailsLoading
  ]);

  const filteredAllSectionsWithSeriesById = useMemo(() => {
    if (!filters.publishedOnly) {
      return Object.values(allSectionsWithSeriesById);
    }
    return Object.values(allSectionsWithSeriesById).filter((sec) => sec.isPublished);
  }, [filters.publishedOnly, allSectionsWithSeriesById]);

  const onKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      changeFilters({ query: tempQuery });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.filters}>
        <SearchField
          placeholder={searchText}
          variant="outlined"
          onKeyPress={onKeyPressed}
          className={classes.search}
          onChange={(event) =>
            setTempQuery(isEmpty(event.target.value) ? undefined : event.target.value)
          }
        />
        <FilingTypeFilters
          onFilter={(selectedFilingTypes) => changeFilters({ filingTypes: selectedFilingTypes })}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={false}
              onChange={(_, checked) => changeFilters({ publishedOnly: checked })}
              color={'primary'}
            />
          }
          label="Updated Fundamentals Only"
          checked={filters.publishedOnly}
        />
      </Box>
      <Box className={classes.tableContainer}>
        <AppDataGrid
          columns={mainColumns}
          rows={filteredAllSectionsWithSeriesById}
          autoHeight={true}
          loading={isSectionsLoading}
          getRowId={(row) => row.id}
          isRowSelectable={() => false}
          filterMode={'server'}
          disableMultipleColumnsFiltering={true}
          onDetailPanelExpandedRowIdsChange={(ids) => setSectionId(ids[ids?.length - 1] as string)}
          detailPanelExpandedRowIds={sectionId ? [sectionId] : []}
          getDetailPanelHeight={() => 'auto'}
          getDetailPanelContent={(row) => (
            <AppDataGrid
              columns={columns}
              rows={(row.row as SectionWithSeries).series}
              getRowId={(series: Series) => series.id}
              rowHeight={40}
              autoHeight={true}
              loading={isFetchingNextSectionDetails || isSectionDetailsLoading}
              pageSize={PAGE_SIZE}
              rowsPerPageOptions={[]}
              onPageChange={(page) => {
                setPageNumberBySectionId({
                  ...pageNumberBySectionId,
                  [(row.row as SectionWithSeries).id]: page
                });
                page > 0 && !sectionDetails?.pageParams?.includes(page)
                  ? fetchNextSectionDetailsPage({ pageParam: page })
                  : undefined;
              }}
              page={pageNumberBySectionId[(row.row as SectionWithSeries).id] ?? 0}
              pagination={true}
              rowCount={(row.row as SectionWithSeries).seriesIds?.length ?? -1}
              key={(row.row as SectionWithSeries).id}
            />
          )}
          rowHeight={70}
        />
      </Box>
    </Box>
  );
};
