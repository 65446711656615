import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import PasswordStrengthBar from 'react-password-strength-bar';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import useLocal from 'localization';

import useGetParameter from 'components/Popup/hooks/useGetParameter';

import { ResetPasswordSelectors, ResetPasswordTypes, actions } from './duck';

import useStyles from './style';
import { Link } from 'react-router-dom';
import routes from 'components/Router/routes';
import { History } from 'core';
import { validateComplexity, validateIdenticalCharacters } from '../../utils/passwordValidators';
import { isEmpty } from 'lodash';

const initialValues = {
  password: '',
  confirmPassword: ''
};

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const error = useSelector(ResetPasswordSelectors.getError);
  const hasError = useSelector(ResetPasswordSelectors.hasError);
  const isLoading = useSelector(ResetPasswordSelectors.isLoading);

  const isSuccess = useSelector(ResetPasswordSelectors.isSuccess);

  const passwordRequired = useLocal('password_required');
  const passwordCharacters = useLocal('password_characters');
  const passwordMax = useLocal('password_max');
  const passwordConsecutiveIdentical = useLocal('password_consecutive_identical');
  const passwordComplexity = useLocal('password_complexity');

  const uid = useGetParameter('uid');
  const token = useGetParameter('token');
  if (!uid || !token) {
    History.push(routes.LOGIN);
  }

  useEffect(() => {
    return () => {
      dispatch(actions.resetPasswordClear());
    };
  }, [dispatch]);
  const resetPasswordSuccess1 = useLocal('reset_password_success_1');
  const resetPasswordSuccess2 = useLocal('reset_password_success_2');
  const resetPasswordSuccess3 = useLocal('reset_password_success_3');

  const resetPasswordObjectShape = Yup.object({
    password: Yup.string()
      .required(passwordRequired)
      .min(8, passwordCharacters)
      .max(128, passwordMax)
      .test(
        'consecutiveIdentical',
        passwordConsecutiveIdentical,
        (password) => password === undefined || validateIdenticalCharacters(password)
      )
      .test({
        name: 'complexity',
        test: (value, context) => {
          if (value === undefined) {
            return true;
          }
          const errorMessage = validateComplexity(value, passwordComplexity);
          if (errorMessage) {
            return context.createError({ message: errorMessage });
          }
          return true;
        }
      }),
    confirmPassword: Yup.string()
      .required('Confirm password is required.')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const formikObj = useFormik<ResetPasswordTypes.resetPasswordField>({
    initialValues,
    validateOnBlur: true,
    validationSchema: resetPasswordObjectShape,
    onSubmit: ({ password }) => {
      dispatch(actions.resetPasswordRequest(password, token, uid));
    }
  });

  return (
    <form onSubmit={formikObj.handleSubmit}>
      <Dialog maxWidth="xs" open={true} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.title} id="form-dialog-title">
          <h2>{useLocal('reset_password')}</h2>
          <IconButton
            data-testid="api-settings-close-btn"
            onClick={() => History.push(routes.LOGIN)}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        {isSuccess && (
          <Alert severity="success" data-testid="server-error">
            {resetPasswordSuccess1}
            <Link to={routes.LOGIN}> {resetPasswordSuccess2} </Link> {resetPasswordSuccess3}
          </Alert>
        )}

        {hasError && (
          <Alert severity="error" data-testid="server-error">
            {error}
          </Alert>
        )}
        <DialogContent>
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel
              htmlFor="password"
              error={Boolean(formikObj.errors.password && formikObj.touched.password) || hasError}
            >
              {useLocal('new_password')}
            </InputLabel>
            <OutlinedInput
              id="password"
              inputProps={{ 'data-testid': 'reset-password' }}
              name="password"
              type="password"
              labelWidth={108}
              onChange={formikObj.handleChange}
              value={formikObj.values.password}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.password && formikObj.touched.password) || hasError}
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.password && formikObj.touched.password)}
            >
              {formikObj.touched.password && formikObj.errors.password}
            </FormHelperText>
            {formikObj?.values?.password && isEmpty(formikObj?.getFieldMeta('password').error) && (
              <PasswordStrengthBar password={formikObj.values.password} />
            )}
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel
              htmlFor="confirmPassword"
              error={Boolean(formikObj.errors.password && formikObj.touched.password) || hasError}
            >
              {useLocal('confirm_password')}
            </InputLabel>
            <OutlinedInput
              id="confirmPassword"
              inputProps={{ 'data-testid': 'confirm-password' }}
              name="confirmPassword"
              type="password"
              labelWidth={133}
              onChange={formikObj.handleChange}
              value={formikObj.values.confirmPassword}
              onBlur={formikObj.handleBlur}
              error={
                Boolean(formikObj.errors.confirmPassword && formikObj.touched.confirmPassword) ||
                hasError
              }
            />

            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.confirmPassword && formikObj.touched.confirmPassword)}
            >
              {formikObj.touched.confirmPassword && formikObj.errors.confirmPassword}
            </FormHelperText>
          </FormControl>

          <DialogActions className={classes.dialogActions}>
            <Button
              type="submit"
              color="primary"
              data-testid="login-submit"
              variant="contained"
              disabled={isLoading || !formikObj.isValid}
              onClick={formikObj.submitForm}
            >
              {useLocal('reset_password')}
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default ResetPassword;
