import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  iconLink: {
    minWidth: '20em'
  },
  container: {
    [theme.breakpoints.down('xl')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2em'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '5em'
    }
  }
}));
