const IDENTICAL_CHARACTERS = /.*(.)\1\1+.*/;

const COMPLEXITY_REGEX = [
  { regex: /.*[A-Z].*/, message: 'at least 1 uppercase character (A-Z)' },
  { regex: /.*[a-z].*/, message: 'at least 1 lowercase character (a-z)' },
  { regex: /.*[0-9].*/, message: 'at least 1 digit (0-9)' },
  { regex: /.*[^a-zA-Z0-9].*/, message: 'at least 1 special character' }
];

const ALLOWED_PASS = 3;

export const validateIdenticalCharacters = (password: string) => {
  return !IDENTICAL_CHARACTERS.test(password);
};

export const validateComplexity = (password: string, complexityMessage: string) => {
  const errors = [];
  let passedCases = 0;
  for (const { regex, message } of COMPLEXITY_REGEX) {
    if (regex.test(password)) {
      passedCases++;
    } else {
      errors.push(message);
    }
  }
  if (passedCases < ALLOWED_PASS) {
    return complexityMessage
      .replace('%1', `${ALLOWED_PASS - passedCases}`)
      .replace('%2', errors.join(', '));
  }
  return null;
};
