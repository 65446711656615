const MODELS = '/models';
const INDUSTRY_MODELS = '/industry-models';
const DASHBOARD = '/dashboard';
const LOGIN = '/login';
const REGISTER_THANKYOU_POPUP = '/register-thank-you';
const THANKYOU_VERIFY_EMAIL = '/verify-email';
const FORGET_PASSWORD = '/forget-password';
const RESET_PASSWORD = '/reset-password';
const TAG_VIEWER = '/src/:id';
const DOC_VIEWER = '/document/:id';
const TEXT_FUNDAMENTAL = '/text-fundamental';
const SIGN_UP = '/sign-up';
const VERIFY_EMAIL = '/verify-email';
const INQUIRE_HERE_POPUP = '/inquire-here';
const SAMPLE = '/sample';
const REGISTRATION = '/register';
const HOME = '/';
const Live_PREVIEW = '/preview/:companyId';

/**
 * Generic popups
 */

const REQUEST_A_MODEL = '?popup=request-a-model';
const ADD_NEW_MODEL = '?popup=add-new-model';
const API_SETTINGS = '?popup=api-settings';

const EXCEL_PLUGIN = '?popup=excel-plugin';
const DOWNLOAD_MODEL = '?popup=download-model';
const UNSUBSCRIBE_MODEL = '?popup=unsubscribe-model';

/**
 * Error Pages
 */

const ERROR_404 = '/404';
const ERROR_403 = '/403';
const ERROR_401 = '/401';
const ERROR_500 = '/500';

export default {
  MODELS,
  INDUSTRY_MODELS,
  API_SETTINGS,
  DASHBOARD,
  HOME,
  LOGIN,
  REGISTER_THANKYOU_POPUP,
  THANKYOU_VERIFY_EMAIL,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  ERROR_404,
  ERROR_403,
  ERROR_401,
  ERROR_500,
  REQUEST_A_MODEL,
  ADD_NEW_MODEL,
  EXCEL_PLUGIN,
  DOWNLOAD_MODEL,
  TAG_VIEWER,
  DOC_VIEWER,
  TEXT_FUNDAMENTAL,
  SIGN_UP,
  REGISTRATION,
  SAMPLE,
  VERIFY_EMAIL,
  INQUIRE_HERE_POPUP,
  UNSUBSCRIBE_MODEL,
  Live_PREVIEW
};
