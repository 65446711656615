import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import EventIcon from '@material-ui/icons/Event';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { History } from 'core';
import routes from 'components/Router/routes';
import InfoIcon from '@material-ui/icons/Info';
import useLocal from 'localization';
import ModelFreeTrierTooltip from 'components/ModelFreeTrierTooltip';

import Props from './types';
import useStyles from './style';
import WhiteTooltip from 'components/WhiteTooltip';
import { actions, modelsSelectors } from 'pages/Models/duck';
import { UserDetailSelectors } from 'userDetail';
import { useDispatch, useSelector } from 'react-redux';
import modelActions from '../../pages/Models/duck/actions';

const ModelItem: React.FC<Props> = ({
  showSubscribe = true,
  showNotAllowed = false,
  showDownload = true,
  company,
  onSubscribeModel,
  id,
  latestQuarter,
  clientModelIndex,
  subscriptionPendingModel,
  noCredit = false,
  isTrialTier
}: Props) => {
  const classes = useStyles();
  const download = useLocal('download');
  const subscribe = useLocal('subscribe');
  const unsubscribe = useLocal('unsubscribe');
  const lastUpdatedLable = useLocal('last_updated');
  const dispatch = useDispatch();
  const { name, ticker, id: companyId } = company;
  // const date = moment(createdAt).format('YYYY-MM-DD');
  // const time = moment(createdAt).format('hh:mm:ss');

  const showTooltip = showNotAllowed || noCredit;
  const isFreeTier = useSelector(UserDetailSelectors.isFreeTier);
  const earningsUpdateNotificationByCompanyId = useSelector(
    modelsSelectors.earningsUpdateNotifications
  );
  const companyEarningsUpdate = earningsUpdateNotificationByCompanyId[companyId];
  return (
    <Grid item xs={12} sm={12} md={4} data-testid="model-grid-item">
      <Box className={classes.modelItem}>
        <Box color="text.secondary" display="flex" alignItems="center">
          <EventIcon fontSize="inherit" />{' '}
          <span className={classes.dateTime}>
            {lastUpdatedLable}: {latestQuarter}
            {parseInt(companyEarningsUpdate?.notificationCount) > 0 && (
              <Button
                variant={'text'}
                color={'primary'}
                onClick={() => window.open(`/preview/${company.id}`)}
              >
                {` (${companyEarningsUpdate?.notificationCount}) new updates`}
              </Button>
            )}
          </span>
        </Box>
        <Box className={classes.modelName}>
          <a
            rel="noreferrer"
            target="_blank"
            className={classes.modelPreviewLink}
            href={`${process.env.REACT_APP_BACKEND_URL}/preview/${id}`}
            data-testid="model-name"
          >
            <InfoIcon />
          </a>
          {ticker}-{name}
        </Box>
        <Box>
          {showDownload && (
            <Button
              variant="contained"
              color="primary"
              className={clsx(classes.buttons, classes.download)}
              data-testid="download-model-btn"
              onClick={() => {
                dispatch(actions.selectCompany(company));
                History.push(`${routes.DOWNLOAD_MODEL}&modelId=${id}&ticker=${company?.ticker}`);
              }}
            >
              {download}
            </Button>
          )}

          {showSubscribe &&
            (showTooltip ? (
              <WhiteTooltip
                title={<ModelFreeTrierTooltip showNotAllowed={showNotAllowed} />}
                interactive
              >
                <span>
                  <Button
                    color="primary"
                    disabled={showTooltip || subscriptionPendingModel === id}
                    data-testid="subscribe-model-btn"
                    onClick={() => {
                      if (onSubscribeModel) {
                        onSubscribeModel(company.id, id, clientModelIndex, company?.ticker);
                        dispatch(actions.selectCompany(company));
                      }
                    }}
                    className={clsx(classes.buttons, { [classes.unSubscribe]: showNotAllowed })}
                    variant="outlined"
                  >
                    {subscribe}
                    {subscriptionPendingModel === id && <CircularProgress size={24} />}
                  </Button>
                </span>
              </WhiteTooltip>
            ) : (
              <Button
                color="primary"
                disabled={subscriptionPendingModel === id || isTrialTier}
                data-testid="subscribe-model-btn"
                onClick={() => {
                  if (onSubscribeModel) {
                    onSubscribeModel(company.id, id, clientModelIndex, company?.ticker);
                    dispatch(actions.selectCompany(company));
                  }
                }}
                className={clsx(classes.buttons, { [classes.unSubscribe]: showNotAllowed })}
                variant="outlined"
              >
                {subscribe}
                {subscriptionPendingModel === id && <CircularProgress size={24} />}
              </Button>
            ))}
          {showDownload &&
            (!isFreeTier ? (
              <Button
                color="primary"
                variant="outlined"
                data-testid="unsubscribe-model-btn"
                className={clsx(classes.buttons, classes.unSubscribe)}
                onClick={() => {
                  dispatch(actions.selectCompany(company));
                  History.push(routes.UNSUBSCRIBE_MODEL);
                }}
              >
                {unsubscribe}
              </Button>
            ) : (
              ''
            ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default React.memo(ModelItem);
