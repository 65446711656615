import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  icons: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginRight: 0
    }
  },
  yellowBtn: {
    marginBottom: theme.spacing(2),
    background: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    width: '100%',
    height: theme.spacing(7),
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      justifyContent: 'center'
    },
    '&:hover': {
      background: theme.palette.warning.main,
      color: theme.palette.white.main
    }
  },

  blueBtn: {
    marginBottom: theme.spacing(2),
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    width: '100%',
    height: theme.spacing(7),
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      justifyContent: 'center'
    },
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.white.main
    }
  },
  badgeRoot: { width: '11rem' }
}));
