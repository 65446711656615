import React, { useEffect } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import downloadMan from 'assets/downloadMan.svg';
import useGetParameter from '../Popup/hooks/useGetParameter';
import { PARAMS } from '../Popup/constants';

import { actions, modelsSelectors } from 'pages/Models/duck';
import currentStatus from 'pages/Models/duck/types';
import { useDispatch, useSelector } from 'react-redux';

import useLocal from 'localization';

import Props from './types';
import useStyles from './style';
import Mixpanel from '../../mixPanel';

const DownloadModelPopup: React.FC<Props> = ({ isOpened, showDownload = true, onClose }: Props) => {
  const classes = useStyles();
  const download = useLocal('download');
  const annualsWithinQuarters = useLocal('annualsWithinQuarters');
  const annualsOutsideQuarters = useLocal('annualsOutsideQuarters');
  const modelTitle = useLocal('download_model');
  const modelId = useGetParameter(PARAMS.modelId);
  const ticker = useGetParameter(PARAMS.ticker);
  const latestModelStatus = useSelector(modelsSelectors.latestModelStatus);
  const downloadingModelType = useSelector(modelsSelectors.downloadingModelType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (latestModelStatus == currentStatus.SUCCESS) {
      onClose();
      dispatch(actions.downloadModelStatus(currentStatus.RESET, null));
    }
  }, [latestModelStatus, dispatch, onClose]);

  const onDownloadAnnualModels = (type: string) => {
    Mixpanel.track('models:download_model', { modelId, ticker });
    dispatch(actions.downloadModel(modelId as string, type, ticker as string));
  };

  const onhandleClick = (type: string) => {
    Mixpanel.track('models:download_model_separate_annual', { modelId, ticker });
    dispatch(actions.downloadModel(modelId as string, type, ticker as string));
  };
  return (
    <Dialog open={isOpened} onClose={onClose} data-testid="add-new-model-popup" maxWidth="md">
      <DialogTitle disableTypography className={classes.title}>
        <h2>{modelTitle}</h2>
        <IconButton onClick={onClose} data-testid="add-new-model-close-btn">
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contant}>
        <Box display="flex" py={1} justifyContent="space-between" alignItems="center">
          <Box>
            <img src={downloadMan} alt={modelTitle} />
          </Box>
          <Box>
            <Box display="flex" py={1} flexDirection="column">
              <Box className={clsx(classes.rightBoxWrapper)}>
                <Box className={clsx(classes.annualHeading)}>{annualsWithinQuarters}</Box>
                <Box fontSize={14} pb={2}>
                  [QQQQ - A - QQQQ - A]
                </Box>
                {showDownload && (
                  <Box>
                    {[
                      { type: 'clientview', label: 'Download' },
                      {
                        type: 'clientview_with_source_unit_columns',
                        label: 'Download with meta data'
                      }
                    ].map((option, key) => (
                      <Button
                        key={key}
                        variant="outlined"
                        className={clsx(classes.buttons, classes.unSubscribe)}
                        data-testid="download-model-btn"
                        onClick={() => onDownloadAnnualModels(option.type)}
                      >
                        {latestModelStatus == currentStatus.PENDING &&
                        downloadingModelType === option.type ? (
                          <CircularProgress className={classes.spinner} />
                        ) : (
                          ''
                        )}
                        {option.label}
                      </Button>
                    ))}
                  </Box>
                )}
              </Box>
              <Box className={clsx(classes.rightBoxWrapper)}>
                <Box className={clsx(classes.annualHeading)}>{annualsOutsideQuarters}</Box>
                <Box fontSize={14} pb={2} m={1}>
                  [QQQQ - QQQQ - A - A]
                </Box>
                {showDownload && (
                  <Box>
                    {[
                      { type: 'clientview_snapshot', label: 'Download' },
                      {
                        type: 'clientview_snapshot_with_source_unit_columns',
                        label: 'Download with meta data'
                      }
                    ].map((option, key) => (
                      <Button
                        key={key}
                        variant="outlined"
                        className={clsx(classes.buttons, classes.unSubscribe)}
                        data-testid="download-model-btn"
                        onClick={() => onhandleClick(option.type)}
                      >
                        {latestModelStatus == currentStatus.PENDING &&
                        downloadingModelType === option.type ? (
                          <CircularProgress className={classes.spinner} />
                        ) : (
                          ''
                        )}
                        {option.label}
                      </Button>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default DownloadModelPopup;
