import { put, takeLatest } from 'redux-saga/effects';
import { selectState } from 'store';
import { getLocalValue } from 'localization';
import actionTypes from './types';
import { GraphQL, gql } from 'core';
import actions from './actions';

export function* getIndustrySaga() {
  yield put(actions.getIndustriesPending());
  try {
    const {
      data: { sectorsCompsheet }
    } = yield GraphQL.query({
      query: gql`
        query {
          sectorsCompsheet {
            totalNumberOfModels
            sectorList {
              name
              modelCount
            }
          }
        }
      `,
      fetchPolicy: 'cache-first'
    });
    yield put(actions.setIndustries(sectorsCompsheet));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.getIndustriesError(errorMsg));
  }
}

export default function* IndustryModelsSaga() {
  yield takeLatest(actionTypes.GET_INDUSTRIES, getIndustrySaga);
}
