const actionTypes = {
  GET_INDUSTRIES: 'marketplace/IndustryModels/GET_INDUSTRIES',
  GET_INDUSTRIES_PENDING: 'marketplace/IndustryModels/GET_INDUSTRIES_PENDING',
  GET_INDUSTRIES_ERROR: 'marketplace/IndustryModels/GET_INDUSTRIES_ERROR',
  SET_INDUSTIRES: 'marketplace/IndustryModels/SET_INDUSTIRES'
};

export type sectorList = Array<{
  id: string;
  name: string;
  modelCount: number | null;
  industriesSet: Array<{ id: string; name: string }>;
}> | null;
export type sectorData = {
  sectorList: sectorList;
  totalNumberOfModels: number;
};

export type IndustyState = {
  isIndustryLoading: boolean;
  isIndustryError: boolean;
  industryError: string | null;
  sectorsCompsheet: sectorList;
  seletedSectorId: string;
  totalNumberOfIndustries: number | null;
};

/**
 * Industries
 */

export type getIndustryAction = () => {
  type: typeof actionTypes.GET_INDUSTRIES;
};
export type getIndustryPendingAction = () => {
  type: typeof actionTypes.GET_INDUSTRIES_PENDING;
};
export type getIndustryErrorAction = (
  error: string
) => {
  type: typeof actionTypes.GET_INDUSTRIES_ERROR;
  error: string;
};

export type setIndustryAction = (
  sectorsCompsheet: sectorData
) => {
  type: typeof actionTypes.GET_INDUSTRIES;
  sectorsCompsheet: sectorData;
};

export type actionCreatorTypes = {
  type: string;
  sectorsCompsheet?: sectorData;
  error?: string;
  sectorId?: string;
  totalNumberOfIndustries?: number;
};

export default actionTypes;
