import React from 'react';

import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

import ModelFilter from 'components/ModelFilter';
import SidebarRqModel from 'components/SidebarRqModel';
import { SHOW_REQUEST_MODEL } from '@constants';
import useLocal from 'localization';
import { isLoggedIn } from 'core/modules/auth.utils';

import Props, { allModelId, myModelId } from './types';

const ModelSidebar: React.FC<Props> = ({
  sectorList,
  hasSectorError,
  sectorError,
  isSectorLoading,
  selectFilter,
  selectedSector,
  isFreeTier,
  allowedSectors
}: Props) => {
  const industryTitle = useLocal('industries');
  const subscribed = useLocal('subscribed');
  const allModel = useLocal('all_model');
  const extraFilter = isLoggedIn()
    ? [
        { name: subscribed, id: myModelId, isProtected: true },
        { name: allModel, id: allModelId }
      ]
    : [{ name: allModel, id: allModelId }];

  return (
    <Box
      position={{ md: 'fixed', lg: 'fixed', xl: 'fixed' }}
      width={{ md: '15vw', lg: '15vw', xl: '20em' }}
      data-testid="sector-list"
    >
      {isSectorLoading && (
        <>
          {[...Array(16)].map((data, index) => (
            <Skeleton key={`sidebar-skeleton-${data}-${index}`} height={33.2} />
          ))}
        </>
      )}
      {hasSectorError && <Alert severity="error">{sectorError}</Alert>}
      {sectorList?.length && (
        <ModelFilter
          title={industryTitle}
          selectFilter={selectFilter}
          seletedFilterId={selectedSector}
          isFreeTier={isFreeTier}
          allowedSectors={allowedSectors}
          showIcon
          list={[...extraFilter, ...sectorList]}
          key="IndustryFilter"
        />
      )}

      {SHOW_REQUEST_MODEL && <SidebarRqModel key="SidebarRqModel" />}
    </Box>
  );
};

export default React.memo(ModelSidebar);
